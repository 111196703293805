<template>
  <section class="base-page">
    <PageHeader class="base-shadow" />
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <basic-table
      showSelection
      hiddenClear
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">{{
              scope.row.lastModifiedAt | date_time
            }}</span>

            <span v-else-if="column.prop === 'address.country'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.country
              }}
            </span>
            <span v-else-if="column.prop === 'address.province'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.province
              }}
            </span>
            <span v-else-if="column.prop === 'address.city'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.city }}
            </span>
            <span v-else-if="column.prop === 'address.region'">
              {{ isEmprty(scope.row.address) ? "" : scope.row.address.region }}
            </span>
            <span v-else-if="column.prop === 'address.street'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.street }}
            </span>
            <span v-else-if="column.prop === 'star'">
              <i
                class="el-icon-star-off"
                v-if="!scope.row.star"
                style="font-size: 19px"
              ></i>
              <i
                class="el-icon-star-on text-start"
                v-if="scope.row.star"
                style="font-size: 24px"
              ></i>
            </span>
            <span v-else-if="column.prop === 'name'">
              <span
                style="padding-left: 5px"
                title="点击查看详情"
                class="text-nav"
                @click="onShowDetail(scope.row)"
                >{{ scope.row.name }}</span
              >
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              :disabled="!scope.row.canClaim"
              @click.native.prevent="getMyClient(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              认领该客户
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          type="success"
          size="small"
          @click="onMultipleclaim"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleClaimLoading"
          >批量认领 {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetClientPublicList, GetClaimCheck } from "./api";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import publicClientComonControl from "./mixins/comonControl";
import FilterSection from "@/views/client/components/FilterSection";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import { ReadLocalColumn } from "@/utils/table";
export default {
  components: {
    BasicTable,
    FilterSection,
    PageHeader,
  },
  mixins: [publicClientComonControl],
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      enableLoading: false,
      disableLoading: false,
      multipleFormDialog: false,
      multipleClaimLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {},
    };
  },

  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "star",
            label: "星标",
            showColumn: true,
            fixed: "left",
            wisth: "40px",
            align: "center",
            sortable: false,
          },
          {
            prop: "name",
            label: "客户名称",
            minWidth: 150,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "ownerName",
            label: "负责人",
            minWidth: 150,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "stageName",
            label: "客户阶段",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "sourceName",
            label: "客户来源",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "typeName",
            label: "客户类型",
            showColumn: true,
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "followUpStatusName",
            label: "跟进状态",
            showColumn: true,
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "brand",
            label: "品牌",
            showColumn: true,
            width: 130,
            fixed: null,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "address.country",
            label: "国家",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.province",
            label: "省份",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.city",
            label: "城市",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.region",
            label: "地区",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "address.street",
            label: "街道",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建时间",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            showColumn: true,
            minWidth: 150,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "最后修改日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "最后修改人",
            showColumn: true,
            minWidth: 180,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: "120",
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetClientPublicList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              canClaim: false,
            };
          });
          this.tableData.forEach((item) => {
            this.onCheckItemClaim(item);
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "createdAt";
      this.orderDirection = "descending";
      this.onResetFilter();
      this.onRefresh();
    },
    onShowDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.id,
        },
      });
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onResetFilter() {
      for (const key in this.tableFilters) {
        if (typeof this.tableFilters[key] === "object") {
          if (this.tableFilters[key] instanceof Array) {
            this.tableFilters[key] = [];
          } else {
            this.tableFilters[key] = null;
          }
        } else {
          this.tableFilters[key] = "";
        }
      }
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    onCheckItemClaim(item) {
      GetClaimCheck(item.id)
        .then((res) => {
          item.canClaim = res.data;
        })
        .catch(() => {
          item.canClaim = false;
        });
    },
    getMyClient(row) {
      if (row.canClaim) {
        this.claimClient(row.id, row.name)
          .then(() => {
            this.onRefresh();
          })
          .catch((err) => {
            ShowApiError("认领失败", err);
          });
      } else {
        this.$message.warning("对不起，您无权限执行此操作");
      }
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>