import {
    PutClientPublicClaimByIds
} from "../api";
import { ShowApiError } from "@/request/error";
import { FormatMultipleItemIds, GetTableDisableMessage } from "@/utils/table";
export default {
    methods: {
        claimClient(id = "", name = "") {
            return new Promise((resolve, reject) => {
                this.$msgbox({
                    title: "认领提示",
                    message: `是否确定要认领【${name}】客户?`,
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = "执行中...";
                            PutClientPublicClaimByIds([id])
                                .then(() => {
                                    this.$message.success("操作成功!");
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    done();
                                    resolve();
                                })
                                .catch((err) => {
                                    done();
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    ShowApiError("操作请求错误", err);
                                    reject(err);
                                });
                        } else {
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = "";
                            done();
                        }
                    },
                });
            })
        },
        onMultipleclaim() {
            if (this.multipleSelected.length <= 0) {
                return false;
            }
            this.multipleClaimLoading = true;
            let { ids, nameString, unableControl } = FormatMultipleItemIds({
                multipleSelectes: this.multipleSelected,
                conditionsKey: 'canClaim',
                nameKey: "name",
            });
            let message = GetTableDisableMessage({
                total: this.multipleSelected.length,
                ableControlCount: ids.length,
                unableControlCount: unableControl.length,
                nameString: nameString,
                flag: true,
                ableText: "认领",
                unAbleText: "认领",
            });
            this.$msgbox({
                title: `认领客户提示`,
                message: message,
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                beforeClose: (action, instance, done) => {
                    if (action === "confirm") {
                        if (ids.length <= 0) {
                            this.$message.info("无有效数据可操作");
                            done();
                            this.multipleClaimLoading = false;
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = "";
                            return;
                        }
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = "执行中...";
                        PutClientPublicClaimByIds(ids)
                            .then(() => {
                                this.$message.success("操作成功");
                                this.multipleSelected = [];
                                this.onRefresh();
                            })
                            .catch((err) => {
                                ShowApiError("操作请求错误", err);
                            }).finally(() => {
                                done();
                                instance.confirmButtonLoading = false;
                                instance.confirmButtonText = "";
                                this.multipleClaimLoading = false;
                            });
                    } else {
                        this.multipleClaimLoading = false;
                        instance.confirmButtonLoading = false;
                        instance.confirmButtonText = "";
                        done();
                    }
                },
            });
        }
    }
}