import { getOrderType } from "@/utils/common";
export default {
    data() {
        return {
            tableFilters: {
                createdAt: [],
                lastModifiedAt: [],
                createdById: null,
                lastModifiedById: null,
                followUpStatusId: null,
                departmentIds:null,
                ownerId: null,
                sourceId: null,
                typeId: null,
                stageId: null,
                repeated: null,
                isFetched: null,
                disabled: false,
                claimed:null,
                country: "",
                province: "",
                city: "",
                region: "",
                name: "",
                mainContactName: "",
                mainContactPhone: "",
                tags: [],
            },
            repeatedOptions: [
                { id: true, name: "重复" },
                { id: false, name: "不重复" },
                { id: null, name: "全部" },
            ],
            orderPropObj: {
                departmentIds: "owner.department.id",
                ownerName: "owner.name",
                sourceName: "source.name",
                typeName: "type.name",
                followUpStatusName: "followUpStatus.name",
                mainContactPhoneNo: "contacts.phoneNo",
                followUpStatusId: "followUpStatus.id",
                country: "address.country",
                province: "address.province",
                city: "address.city",
                region: "address.region",
                mainContactName: "contacts.name",
                mainContactPhone: "contacts.phoneNo",
            },
        }
    },
    methods: {
        filterParams() {
            let filterParams = {};
            for (const key in this.tableFilters) {
                if (this.orderPropObj[key]) {
                    filterParams[this.orderPropObj[key]] = this.tableFilters[key];
                } else {
                    filterParams[key] = this.tableFilters[key];
                }
            }
            if (filterParams.repeated === null) {
                delete filterParams.repeated;
            }
            return filterParams;
        },
        getTableOrderParams() {
            let orderPropName = "";
            if (this.orderProp !== "" && this.orderDirection !== null) {
                orderPropName = Object.prototype.hasOwnProperty.call(
                    this.orderPropObj,
                    this.orderProp
                )
                    ? this.orderPropObj[this.orderProp]
                    : this.orderProp;
                return `${orderPropName},${getOrderType(this.orderDirection)}`;
            } else {
                return null;
            }
        },
        onTableSortChange({ prop, order }) {
            if (order === null) {
                this.orderProp = "";
                this.orderDirection = null;
            } else {
                this.orderDirection = order;
                this.orderProp = prop;
            }
            this.onRefresh();
        },
    }
}